.info-button {
  background-color: #517397;
}
.edit-button {
  background-color: #39b27c;
  color: white;
}
.delete-button {
  background-color: #800000;
}
